module.exports = {
  'app-white': {
    10: 'rgba(255, 255, 255, 0.1)',
    20: 'rgba(255, 255, 255, 0.2)',
    30: 'rgba(255, 255, 255, 0.3)',
    40: 'rgba(255, 255, 255, 0.4)',
    50: 'rgba(255, 255, 255, 0.5)',
    60: 'rgba(255, 255, 255, 0.6)',
    70: 'rgba(255, 255, 255, 0.7)',
    80: 'rgba(255, 255, 255, 0.8)',
    90: 'rgba(255, 255, 255, 0.9)',
    95: '#F5F5F5',
    100: 'rgba(255, 255, 255, 1)',
  },
  'app-black': {
    10: 'rgba(18, 18, 18, 0.1)',
    20: 'rgba(18, 18, 18, 0.2)',
    30: 'rgba(18, 18, 18, 0.3)',
    40: 'rgba(18, 18, 18, 0.4)',
    50: 'rgba(18, 18, 18, 0.5)',
    60: 'rgba(18, 18, 18, 0.6)',
    70: 'rgba(18, 18, 18, 0.7)',
    80: 'rgba(18, 18, 18, 0.8)',
    90: 'rgba(18, 18, 18, 0.9)',
    100: 'rgba(18, 18, 18, 1)',
  },
  'app-grey': {
    10: 'rgba(251, 251, 251, 1)',
    15: '#303030cc',
    20: 'rgba(245, 245, 245, 1)',
    25: '#E4E4E4',
    30: 'rgba(236, 236, 236, 1)',
    35: '#FBFBFB',
    40: 'rgba(219, 219, 219, 1)',
    50: 'rgba(165, 165, 165, 1)',
    55: '#A5A5A5',
    60: 'rgba(124, 124, 124, 1)',
    70: 'rgba(94, 94, 94, 1)',
    75: '#4F4F4F',
    80: 'rgba(79, 79, 79, 1)',
    90: 'rgba(56, 56, 56, 1)',
    100: 'rgba(42, 42, 42, 1)',
  },
  'app-primary': {
    10: 'rgba(243, 243, 255, 1)',
    15: 'rgba(111, 89, 244, 1)',
    20: 'rgba(234, 233, 254, 1)',
    25: '#D1CFD8',
    30: 'rgba(214, 213, 255, 1)',
    40: 'rgba(183, 180, 254, 1)',
    50: 'rgba(147, 138, 251, 1)',
    55: '#A49CD1',
    60: 'rgba(112, 90, 248, 1)',
    65: '#6559AE',
    70: 'rgba(92, 55, 235, 1)',
    75: '#816EF9',
    80: 'rgba(78, 38, 219, 1)',
    85: 'rgba(69, 56, 152, 1)',
    90: 'rgba(66, 31, 184, 1)',
    95: '#2D11D6',
    100: 'rgba(55, 27, 151, 1)',
  },
  'app-green': {
    10: 'rgba(244, 251, 234, 1)',
    20: 'rgba(230, 246, 209, 1)',
    25: '#E6F6D1',
    30: 'rgba(207, 238, 168, 1)',
    40: 'rgba(174, 224, 118, 1)',
    50: 'rgba(152, 208, 91, 1)',
    60: 'rgba(124, 199, 49, 1)',
    65: '#629C28',
    70: 'rgba(98, 156, 40, 1)',
    80: 'rgba(67, 110, 29, 1)',
    90: 'rgba(56, 88, 28, 1)',
    100: 'rgba(48, 75, 28, 1)',
  },

  'app-orange': {
    10: 'rgba(255, 249, 240, 1)',
    20: 'rgba(255, 240, 219, 1)',
    25: '#FFF0DB',
    30: 'rgba(255, 224, 184, 1)',
    40: 'rgba(255, 192, 112, 1)',
    50: 'rgba(255, 174, 71, 1)',
    60: 'rgba(255, 146, 27, 1)',
    65: '#F07006',
    70: 'rgba(240, 112, 6, 1)',
    80: 'rgba(199, 85, 7, 1)',
    90: 'rgba(158, 66, 14, 1)',
    100: 'rgba(127, 57, 15, 1)',
  },
  'app-red': {
    10: 'rgba(253, 242, 242, 1)',
    20: 'rgba(254, 230, 229, 1)',
    25: '#FEE6E5',
    30: 'rgba(251, 208, 208, 1)',
    40: 'rgba(248, 169, 170, 1)',
    50: 'rgba(243, 121, 125, 1)',
    60: 'rgba(236, 89, 98, 1)',
    65: '#D5394A',
    70: 'rgba(213, 57, 74, 1)',
    80: 'rgba(188, 36, 57, 1)',
    90: 'rgba(151, 26, 46, 1)',
    100: 'rgba(130, 25, 45, 1)',
  },
}
